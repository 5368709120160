:root{
  --cbi-primary: #ED184B;
}
body {
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  font-family: 'Roboto', sans-serif;
}


a {
  text-decoration: none;
}
.mb-1{
  margin-bottom: 1rem;
}
.flex {
  display: flex;
  flex-direction: var(--flex-dir);
}
.flex-row{
  --flex-dir: row ;
}
.flex-col{
  --flex-dir: column;
}

.flex-center{
  justify-content: center;
  align-items: center;
}

.bottom-cbi-text, .logo-wrapper{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.btn-wrapper{
  width: 100%;
  gap: 1.2rem;
}
.hide{
  display: none;
}

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}