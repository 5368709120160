.dropdown-button {
    padding: 10px 20px;
    border: none;
    color: #fff;
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.caret {
    border-top: 6px solid #fff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    cursor: default;
}

.caret-wrapper{
    z-index: 6;
    position: absolute;
    left: 85%;
    width: calc(15%);
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

}

.caret-wrapper:hover{
    opacity: 0.5;
}

.caret-rotate{
    transform: rotate(270deg);
}

.dropdown-menu{
    position: absolute;
    list-style-type: none;
    margin: 0;
    padding: 0.5rem;
    width: 92px;
    top:3.2rem;
    right: 0;
    color:white;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: var(--cbi-primary);
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:0.3em;
    overflow: hidden;
    cursor: default;
}

.opts{
    text-align: center;
    padding:5px;
    width: 100%;
}

.opts:hover{
    opacity: 0.7;
    cursor: pointer;
}

.hide{
    display: none;
}